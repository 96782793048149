//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProfilePageMixin from "~/node_modules/sitis-nuxt/lib/mixins/ProfilePageMixin";

export default {
  name: "NavigationPanelMobile",
  mixins: [ProfilePageMixin],
  data() {
    return {
      scrlElem: null,
      isOpenMenu: false,
      isFixed: false,
      menuItemsList: [
        {
          img: "go_to_profile",
          title: "profileNavMenu.goToProfile",
          path: "",
        },
        {
          img: "balance",
          title: "profileNavMenu.topupBalance",
          path: "/profile/pay",
        },
        {
          img: "active-number",
          title: "profileNavMenu.activeNumber",
          path: "/profile",
        },
        {
          img: "statistic-number",
          title: "profileNavMenu.queryStatistics",
          path: "/profile/statistics",
        },
        {
          img: "history-number",
          title: "profileNavMenu.balanceHistory",
          path: "/profile/history",
        },
        {
          img: "active-number",
          title: "profileNavMenu.tariffs",
          path: "/profile/tariffs",
        },
        {
          img: "settings",
          title: "profileNavMenu.settings",
          path: "/profile/settings",
        },
        {
          img: "settings",
          title: "profileNavMenu.referralProgram",
          path: "/profile/referral-program",
        },
      ],
    };
  },
  props: {
    balance: {
      type: Number,
      default() {
        return 0;
      },
    },
    isHasUser: {
      type: Boolean,
      default: false,
    },
    isLogin: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    async onLogoutProfile() {
      this.userLogout();

      this.$router.replace(this.localePath("/"));
    },
    handleScroll({target}) {
      const isFixed = Boolean((target?.scrollTop || 0) > 1);
      if (this.isFixed !== isFixed) {
        this.isFixed = isFixed;
      }
    },
  },

  computed: {
    getCurrency() {
      return this.$store.getters["currency/currentCurrency"];
    },
    getActiveItem() {
      let activeItem = this.menuItemsList.find(
        (t) => t.path === this.$route.path
      );
      if (!activeItem) {
        activeItem = this.menuItemsList.find((t) => t.path === "");
      }
      return [activeItem];
    },
    getItemList() {
      let itemList = this.menuItemsList;
      itemList = itemList.filter(
        (t) => t.path !== "" && t.path != this.$route.path
      );
      return itemList;
    },
  },
  mounted() {
    this.scrlElem = document.querySelector(".drawer-content");
    this.scrlElem.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    this.scrlElem.removeEventListener("scroll", this.handleScroll);
  },
};
