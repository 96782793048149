//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import languagesInfo from '~/constant/languiges-info';

export default {
  name: "LangToggle",
  data() {
    return {
      isLanOpen: false
    }
  },
  watch: {
    isLanOpen() {
      if (!this.isLanOpen) {
        this.$store.commit('alternateLink/setLink', {});
      }
    }
  },
  computed: {
    onFilterLocale() {
      return Object.keys(languagesInfo).filter(t => {
        return !languagesInfo?.[t].isDifferentDomain
      })
    },
    // isLangTest() {
    //   return this.$route?.query?.['lang-test'] || null;
    // }
  },
  methods: {
    onClose() {
      this.isLanOpen = false;
    },

    changeLanguage(language) {
      const storeLinks = this.$store.state.alternateLink;
      const langInfo = languagesInfo?.[language] || null;
      const isProd = !process.env.isDev;
      const domain = isProd ? langInfo.domain : langInfo.devDomain;

      if (storeLinks.link[language]) {
        return storeLinks.link[language];
      }

      if (langInfo && langInfo.isDifferentDomain) {
        let slug = '';

        if (this.onFilterLocale.some(t => this.$route.path.startsWith(`/${t}/`))) {
          slug = this.$route.path.replace(/^\/([^/]{2})\//, '/');

        } else if (this.$route.path === '/' || this.onFilterLocale.some(t => `/${t}` === this.$route.path)) {

          slug = '';
        } else {

          slug = this.$route.path;
        }
        return `${domain}${slug}`;
      }
      //если домен общий то возвращаем его в i18n
      const lang = language === 'en' ? '' : language;

      if (this.$nuxt.$i18n.differentDomains) {
        const slug = this.$route.path;

        return `${domain}${slug !== '/' ? slug : ''}`;
      } else if (this.$route.path === '/' || this.onFilterLocale.some(t => `/${t}` === this.$route.path)) {
        return `/${lang}`;
      }

      return this.switchLocalePath(language);
    },
  }
}
