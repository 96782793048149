//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseModal from "~/components/modals/BaseModal.vue";

let timeOutOpenModal = null;

export default {
  name: "AdditionalCashbackAccrual",

  data: function () {
    return {}
  },

  components: {
    BaseModal
  },

  computed: {
    isUser: function () {
      return Boolean(this.$store.state?.user?.user?.id)
    },
    getCurrency() {
      return this.$store.getters["currency/currentCurrency"];
    },

    message: function () {
      const fromBalance = this.$convertCurrency(1000);
      const currency = Boolean(this.getCurrency === 'rub') ? '₽' : '$';

      let message = this.$t('additionalCashbackAccrual.message');

      return message.replace('{{balance}}', `${fromBalance} ${currency}`)
    },
  },

  watch: {
    isUser: async function () {
      await this.getTransactions();
    }
  },

  mounted: async function () {
    await this.getTransactions();
  },
  destroyed: async function () {
    clearTimeout(timeOutOpenModal);
  },

  methods: {
    getTransactions: async function () {
      const isScip = this.$cookies.get("is-additional-cashback-accrual") || false;
      if (!!isScip) {
        return
      }

      // const isUser = this.isUser;
      // if (!isUser) {
      //   return isUser
      // }

      const transactions = await this.$api.agent.transport.get(`/sms-users/get-transactions?type=incoming&page=1&per-page=1`).then((res) => {
        return res.data
      }).catch(() => {
        return []
      });
      if (transactions.length > 0) {
        return
      }

      const isStartTime = this.$cookies.get("start-timeout-additional-cashback-accrual");
      if (!!isStartTime) {
        timeOutOpenModal = setTimeout(() => {
          this.openModal();
        }, 5000);

        return
      }

      const timestamp = Date.now() + 120000;
      this.$cookies.set("start-timeout-additional-cashback-accrual", timestamp);

      timeOutOpenModal = setTimeout(() => {
        this.openModal();
      }, 5000);
    },

    routeProfilePay: function () {
      this.closeModal();
      this.$router.push('/profile/pay');
    },

    openModal: function () {
      const startDateNow = this.$cookies.get("start-timeout-additional-cashback-accrual");
      const currentDateNow = Date.now();

      if (startDateNow > currentDateNow) {
        timeOutOpenModal = setTimeout(() => {
          this.openModal();
        }, 5000);

        return
      }

      this.$cookies.set("is-additional-cashback-accrual", "true");
      this.$modal.show("additional-cashback-accrual");
      this.$cookies.remove("start-timeout-additional-cashback-accrual")
    },
    closeModal: function () {
      this.$modal.hide('additional-cashback-accrual');
    },
  }
}
