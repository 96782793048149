export default {
  header: {
    profile: "Profil",
    loginButton: "Giriş",
    registrationBtn: "Kaydol",
    feedback: "Geri bildirim",
    languageToggle: {
      en: "İngilizce",
      ru: "Rusça",
      cn: "Çince",
      tr: 'Türkçe'
    }
  },
  profileNavMenu: {
    goToProfile: "Hesaba git",
    activeNumber: "Alınan numaralar",
    queryStatistics: "Sorgu istatistikleri",
    balanceHistory: "Bakiye geçmişi",
    tariffs: "Tarifeler",
    settings: "Ayarlar",
    balance: "Bakiye",
    topupBalance: "Doldur",
    topupAlert: "",
    logout: "Çıkış",
    referralProgram: "Yönlendirme programı",
    saleAgreement: "Lisans satışı"
  },
  profile: {
    activeNumbers: {
      title: "Alınan numaralar",
      noActiveNumbers: "Aktif numara yok",
      serverError: "Sunucu hatası",
      status: "Durum",
      waitingForSms: "SMS bekleniyor",
      time: "Zaman",
      banned: "Blokeli mi?",
      cancel: "İptal",
      message: "Kod kabul edildi ve ödemesi yapıldı. Servis tarafındaki kodu girin",
      messageMultipleSms: "Kalan süre içinde servisten ücretsiz olarak yeni kodlar alabilirsiniz",
      blockModalMessage1: "Bu numara servis tarafında engellendi mi? <br />Bu durumu bize bildirin; numarayı arama sonuçlarından kaldıralım",
      blockModalMessage2: "Para anında bakiyenize iade edilecektir. <br />Aynı ülkede veya başka bir ülkede yeni bir numara satın almayı deneyin",
      blockBtn: "Evet, numara bloke edilmiş",
      moreSms: "Tekrar SMS",
      availableIn: "Sonrasında mevcut: ",
      waitingSms: "SMS bekleniyor"
    },
    queryStatistics: {
      title: "Sorgu istatistikleri",
      noQuery: "Sorgu yok",
      buyFor: "Satın al:"
    },
    balanceHistory: {
      title: "Bakiye geçmişi",
      outgoing: "Harcamalar",
      incoming: "Yüklemeler",
      showMore: "Daha fazla göster",
      noHistory: "Geçmiş yok",
      numberOrder: "Fatura No. kapsamında ödeme"
    },
    tariffs: {
      title: "Tarifeler"
    },
    settings: {
      title: "Ayarlar",
      sound: "Kod alındığında ses oynat",
      infoAboutServices: "Aktif satın alımlar hakkında bilgi göster",
      twoFactorEnabled: "İki faktörlü kimlik doğrulama",
      infoAboutBalance: "E-posta bildirimi için alt bakiye eşiği",
      apiKey: "API anahtarı",
      message: "API protokolü aracılığıyla siteyle etkileşim kurmak için API anahtarı oluşturun",
      buttonUpdate: "API Anahtarı güncelle",
      updateError: "Güncelleme hatası",
      settingsChanged: "Ayarlar değiştirildi",
      errorChangingSettings: "Ayarlar değiştirilirken hata oluştu",
      phoneNumber: "Telefon numaranız",
      playceholderPhone: "Numaranızı girin",
      refParams: {
        refLink: "Yönlendirme programı raporu:",
        refOptions: "Yönlendirme programı parametreleri:"
      }
    },
    myPersonalInformation: {
      title: "Kişisel bilgileriniz",
      yourEmail: "E-posta adresiniz",
      yourName: "Adınız"
    },
    referralProgram: {
      title: "Yönlendirme programı",
      referralLinkTitle: "Yönlendirme bağlantısı",
      buttonCopy: "Kopyala",
      buttonDownload: "İndir",
      topUpAmount: "Yönlendirmeleriniz tarafından harcanan",
      youHaveAlreadyReceived: "Alınan yönlendirme ücretleri",
      awaiting: "Yönlendirme bakiyesi",
      detailing: "Yönlendirme sistemi raporu",
      derived: "Bunlardan; çıkarılan",
      formTitle: "Başvur",
      formButton: "Para çekme talebi",
      participatePromotion: "Kampanyaya katıl"
    }
  },
  profilePay: {
    sectionBalanceReplenishment: {
      title: "Bakiye yükleme",
      cardButtonSelect: "Seç",
      paymentTitle: "Ödeme",
      paymentMessage: "Her zaman site arayüzü üzerinden ödeme butonuna tıklayarak ödeme yapın, ancak o zaman parayı doğru bilgileri kullanarak aktarabilirsiniz",
      paymentMessageAlipay: "Hizmet yalnızca Çin Anakarası Onaylı kullanıcıları destekler. Aşağıdaki tüm AliPay kullanıcılarına yönelik bir yöntemi deneyebilirsiniz.",
      paymentPlaceholder: "Tutarı girin *",
      paymentButton: "Öde",
      paymentErrorAmount: "Yükleme miktarı 0'dan büyük olmalıdır",
      payComment: "Yükleme komisyonu",
      plasticCard: "Banka kartları: Rusya, yöntem No.2",
      minAmount: "Minimum ödeme {minPrice} ₽ ",
      minAmountMessage: "Minimum ödeme {minPrice} ₽",
      payMessageForPayeer: "Belirtilen yükleme yöntemini seçerek, grizzlysms.com hizmetleri GRZL PTE LTD firması tarafından sağlanacaktır. Devam etmek için aşağıdaki belgeleri kabul etmeniz gerekmektedir. ",
      payMessageForPaypalych: "Banka kartları: Rusya, yöntem No.4",
      som: "Banka kartları: Tüm dünya, yöntem No.2",
      payMessageForNut: "Banka kartları: Tüm dünya, yöntem No.3",
      stripe: "Banka kartları: Tüm dünya, yöntem No.1 (Stripe)",
      bankCardsTemplateRussia: "Banka kartları: Rusya, yöntem No.3",
      payMessageForTether: "Önemli: Ödeme sistemi tarafından belirtilen tutarı tam olarak yatırın. Aksi takdirde, para yatırılmayacak ve para iadesi 10$'a mal olacaktır.",
      alipayTitle: "AliPay ile ödeme yapmak için QR kodunu kullanın:",
      alipayText: "...ve kayıt e-posta adresinizi bize yazın ve sohbette ödemenin ekran görüntüsünü bize gönderin(↘).<br/>Bakiyeniz otomatik olarak doldurulamayabilir<br/>Lütfen bize yazın. Minimum ödeme 10 Yuan'dır.",
      paymentMessageForConvertor: "Ödenecek tutar, {currency}:",
      paymentMessageForConvertorWithCommission: "Komisyon dahil ödenecek tutar, {currency}:",
      willBeCredited: "Kredilendirilecek, {currency}:",
      messageForConverterDollars: "Dolar kabul ediyoruz ve bunları döviz kuru üzerinden rubleye çeviriyoruz: <span> {rate} </span> bir dolar karşılığında ₽",
      getNumbersFor: 'Getting number for {name}',
      usdHelpMessage: 'The information is provided for reference. Please consider the amount indicated in the interface of the payment instrument as a priority. Due to exchange rate fluctuations, the amounts may vary slightly.'
    },
    aliPay: {
      title: "AliPay and QQpayments",
      messageStep1: "1. Aşağıdaki bağlantıya tıklayın, tutarı seçin ve ödemeyi yapın:",
      messageStep2: "2. SMS Grizzly bakiyenize para eklemek için çift kodunu girin:",
      send: "Gönder",
      couponNotFound: "Kupon bulunamadı",
      placeholderPassword: "Eklemek için kodu girin",
      waitingForRedirectPayment: "Ödeme için siteye geçmeyi bekleyin",
      paymentCodeNotCorrect: "Ödeme kodu yanlış",
      buttonGetLink: "AliPay veya QQ ile ödeme yapın Bir kod alın"
    },
    cryptoPayments: {
      label: "Kripto para birimi ile ödeme"
    }
  },
  servicesList: {
    "Выбор_страны": "Ülke seçimi için {name}",
    title: "Ülke seçin",
    titlePopular: "Popüler ülkeler",
    chooseAnother: "Başka seç",
    countryInputPlaceholder: "Ülke seçin",
    selectedLable: "Seç",
    searchInputPlaceholder: "Hizmet arama",
    buttonWaiting: "Bekleyin...",
    buttonBuy: "Al",
    buttonRent: "Kirala",
    message: "Seçilen ülke için şu anda mevcut numara yok",
    buttonUpdate: "Yenile",
    quantity: "adet",
    quantityFew: "az",
    numberTemporarilyUnavailable: "Yalnızca API aracılığıyla kullanılabilir",
    orderFrom100numbers: "Destek hizmeti aracılığıyla 100 numaradan başlayan sipariş",
    labelCountryHighPercentDelivery: "Teslimat %'si yüksek olan ülkeler",
    labelSelectOtherCountry: "Başka ülke seç",
    showMore: "Daha fazla göster",
    from: "üzerinden",
    retail: "perakende",
    authRetail: "sizin fiyatınız",
    wholesale: "toptan",
    telegramMessagePrice: "Toptan satış fiyatlı kanal:",
    telegramMessageBot: "Telegram Mini Uygulaması:",
    telegramMessageNews: "Hizmet haberleri:",
    scrollBtn: "Tüm hizmetler ve ülkeler",
    requestNewServiceForm: {
      title: "Hizmet sun",
      subTitle: "İhtiyacınız olan hizmeti bulamadınız mı? Aşağıda bize bundan daha ayrıntılı olarak bahsedin, durumu düzeltelim. <br /> Ve şu anda bu bölümü kullanmanızı öneriyoruz ",
      anyother: "Başka",
      dataAboutSite: "Site adresi *",
      smsMessage: "SMS metni *",
      countryService: "İstenilen ülke (isteğe bağlı)",
      price: "Numara fiyatı (isteğe bağlı)",
      btnSend: "Gönder",
      backToList: "Listeye dön",
      offerServiceBtn: "Hizmet sun"
    },
    linkToAccount: {
      btnMsg: "{accountName} hesaplar",
      text: "* Hazır {accountName} hesaplarıyla çalışmak bilgisayar becerileri gerektirir; mevcut ülkeler grubu, hesapların kendi kendine kaydedilmesi için kullanılan ülkeler grubundan farklıdır",
    }
  },
  languagesList: {
    "Выбор_сервиса": "Hizmet seçimi",
    "Поиск_по_сервисам": "Hizmetlere göre arama",
    "Выберите_услугу": "Hizmet seçin",
    "Поиск_по_странам": "Ülkelere göre arama",
    "Успешная_покупка_номера": "Numara başarıyla satın alındı. Yakında \"Alınan numaralar\" listenizde görünecektir",
    serviceNotFound: "Hizmet bulunamadı",
    repeatMsg: 'bekle < {min} dk',
    repeatModal: {
      attempt: 'Girişim'
    },
    errorMessages: {
      "NO_NUMBERS": "Numara kullanılamıyor. Tekrar deneyin"
    }
  },
  blog: {
    title: "Blog",
    buttonDetails: "Daha fazla bilgi",
    pageNumber: "Sayfa No.",
    tags: 'Etiketler',
    recentPost: 'Son Gönderi',
    readMore: "Benzer makaleler"
  },
  footer: {
    title: "Çevrimiçi SMS mesajları almak için en iyi hizmet",
    userAgreement: "Kullanıcı Sözleşmesi",
    referralProgram: "Yönlendirme Programı ",
    returnsPolicy: "İade Politikası ",
    personalInformation: "Kişisel Veri İşleme Politikası",
    messageDisabledRussianNumbers: "Rusya'dan gelen telefon numaraları satılık değil",
    footerDisabledRussianNumbers: "Subscriber numbers of Russian mobile operators are not provided for temporary use due to the requirements of the Federal Law \"On Communications\" dated 07.07.2003 N 126-FZ and also on the basis of subparagraph g of paragraph 5 of the Rules for the centralized management of the public communications network, approved by Decree of the Government of the Russian Federation dated February 12, 2020 N 127 \"On approval of the Rules for the centralized management of the public communications network\""
  },
  registration: {
    title: "Kaydol",
    registrationSuccessMessage: " Kullanıcı başarıyla kaydoldu! Kayıt onay bağlantısı e-postaya gönderildi",
    nameInputPlaceholder: "İsim *",
    emailInputPlaceholder: "E-mail *",
    passwordInputPlaceholder: "Parola *",
    passwordConfirmInputPlaceholder: "Doğrulama *",
    buttonRegistration: "Kaydol",
    buttonRegistrating: "Bekleyin...",
    passwordsMismatchMessage: "Parolalar uyuşmuyor",
    backHome: "Ana sayfaya dön",
    loginWithSocialNetworks: "Şununla oturum aç:",
    orRegistrartion: "veya kaydol",
    iAgree: "Kabul ediyorum",
    privacyPolicy: "kişisel verilerin ve çerezlerin işlenmesi politikasını",
    userAgreement: "halka açık teklifi",
    alreadyHaveAccount: "Zaten hesabınız var mı?",
    login: "Yetkilendirme",
    messageSuccessConfirmRegistration: "Kullanıcı başarıyla kaydoldu"
  },
  authorization: {
    title: "Yetkilendirme",
    emailInputPlaceholder: "E-mail",
    passwordInputPlaceholder: "Parola",
    tokenInputPlaceholder: "Kod",
    buttonRegistration: "Yetkilendirme",
    buttonRegistrating: "Bekleyin...",
    or: "veya",
    newUser: "Yeni kullanıcı mı?",
    registration: "Kaydol",
    forgotPassword: "Parolanızı mı unuttunuz?"
  },
  resetThePassword: {
    title: "Parolayı sıfırla",
    buttonReset: "Parolayı sıfırla",
    buttonWaiting: "Bekleyin...",
    emailInputPlaceholder: "E-mail",
    message: "Posta kutunuza şifre sıfırlama bağlantısı gönderildi. Lütfen posta kutunuzu kontrol edin ve talimatları izleyin."
  },
  errorsValidationForms: {
    requiredForFill: "Doldurulması zorunlu",
    emailErrorMessage: "Yanlış email",
    requaredLengthMessage: "Minimum karakter sayısı:"
  },
  advantagesBlock: {
    title: "Bizim avantajlarımız"
  },
  aboutBlock: {
    showMoreBtn: "Daha fazla göster",
    rollUp: "Daralt"
  },
  serviceCard: {
    availableNumbers: "Mevcut numaralar",
    price: "Fiyat",
    buyNow: "Satın al",
    emptyCountryListMessage: "Seçilen hizmet için uygun numara bulunamadı.<br /> Daha sonra tekrar deneyin"
  },
  feedbackForm: {
    title: "Geri bildirim",
    namePlaceholder: "İsim *",
    emailPlaceholder: "Email *",
    messagePlaceholder: "Mesajınız *",
    iAgree: "Kabul ediyorum",
    privacyPolicy: "kişisel verilerin ve çerezlerin işlenmesi politikasını",
    userAgreement: "halka açık teklifi",
    buttonSend: "Gönder",
    buttonRegistrating: "Bekleyin...",
    sendError: "Gönderme hatası",
    applicationIsAccepted: "Talebiniz kabul edildi"
  },
  copyToBuffer: {
    success: "Metin kopyalandı",
    error: "Kopyalama hatası"
  },
  breadcrumbs: {
    homePage: "Ana sayfa",
    blog: "Blog"
  },
  confirm: {
    passwordReset: "Parolayı sıfırla",
    newPassword: "Yeni parola",
    confirmPassword: "Parolayı doğrula",
    confirm: "Doğrula",
    authorization: "Yetkilendirme",
    notMatch: "Parola eşleşmiyor",
    changedSuccessfully: "Parola başarıyla değiştirildi"
  },
  errorPage: {
    pageNotFound: "Sayfa bulunamadı",
    somethingWentWrong: "Bir şeyler ters gitti!",
    backHomepage: "Ana sayfaya dön"
  },
  support: {
    inputSearch: {
      placeholder: "Soru girin"
    }
  },
  successPayment: {
    successMsg: "Ödeme başarılı oldu",
    waitMsg: "Ödeme tamamlanma sürecinde.",
    backHomepage: "Ana sayfaya dön"
  },
  searchPage: {
    placeholder: "Arama...",
    showMore: "Daha fazla göster",
    nothingFoundMsg: "Talebinize ilişkin hiçbir şey bulunamadı",
    'Статьи': "Makaleler",
    'Страницы': "Sayfalar",
    'Все результаты': "Tüm sonuçlar"
  },
  common: {
    downloadFile: "Dosyayı indir"
  },
  lastArticlesBlock: {
    title: "Blog",
    textBtn: "Blogumuz"
  },
  reviewsBlock: {
    title: "Kullanıcılar hakkımızda ne diyor",
    addReviewBtn: "Değerlendirme yaz",
    yourName: "Adınız",
    messageTextarea: "Lütfen değerlendirmenizi gönderin",
    sendReview: "Değerlendirme gönder",
    success: "Değerlendirme başarıyla gönderildi",
    validNameMsg: "Adınızı girin",
    validMsg: "Mesaj girin"
  },
  currencyToggle: {
    tooltipCurrency: "USD cinsinden fiyatlar referans içindir. Bakiye işlemleri sadece RUB cinsinden gerçekleştirilir"
  },
  pagination: {
    showing: "Gösterilen",
    of: "üzerinden"
  },
  additionalCashbackAccrual: {
    message: "<span>{{balance}}</span> üzerinden <span>1.</span> yüklemede <span>+%10</span> bakiye nakit iadesi !",
    buttonCancel: "Tamam",
    buttonPayment: "Bakiye yükle"
  },
  countries: {
    title: "Mevcut ülkeler",
    countries: "Ülkeler",
    popularServices: "Popüler hizmetler",
    anotherServices: "Hizmet seçin"
  },
  botLinkPage: {
    question: "grizzlysms.com hesabınızı bot için <span> {userName}</span> Telegram hesabına gerçekten bağlamak istiyor musunuz?",
    ok: "Evet",
    cancel: "İptal",
    success: "Telegram hesabı başarıyla bağlandı",
    alreadyHaveBot: "Zaten Telegram botunuz var"
  },
  errors: {
    err1: "Hata oluştu. İsteği daha sonra tekrarlayın."
  },
  partnerPage: {
    becomePartner: "Partner ol",
    popUp: {
      title: "Partner ol",
      yourContactPlaceholder: "İletişim bilgileriniz (Telegram, WeChat, WhatsApp, E-posta, diğer)",
      countryPlaceholder: "SIM kartların 'menşe' ülkeleri",
      equipmentPlaceholder: "Ekipmanınız",
      equipmentPortsPlaceholder: "Ekipman portu sayısı",
      aboutPlaceholder: "Hakkınızda bilmemiz gereken başka neler var?",
      send: "Gönder",
      accept1: "Kabul ediyorum",
      accept2: "gizlilik politikasını ve talep edilen verilerin kullanımını",
      errorForm: "Hata oluştu, daha sonra tekrar deneyin",
      successForm: "Başvuru başarıyla gönderildi"
    }
  },
  partnerFormBlock: {
    title: "Partner ol",
    contactTitle: "Telefon, Telegram, WeChat, WhatsApp, E-mail",
    contactPlaceholder: "Tercih ettiğiniz iletişim yöntemi",
    countrySimCard: "SIM kartların 'menşe' ülkeleri",
    countrySimCardPlaceholder: "Virgülle ayrılmış",
    yourEquipment: "Ekipmanınız",
    yourEquipmentPlaceholder: "Ayrıntılı olarak anlatın",
    countPort: "Ekipman portu sayısı",
    countPortPlaceholder: "Rakamlarla belirtin",
    message: "Hakkınızda bilmemiz gereken başka neler var?",
    messagePlaceholder: "İşinizi ayrıntılı olarak açıklayın"
  },
  partnerHeadingBlock: {
    becomePartner: "Partner ol",
    yieldCalculator: "Getiri hesaplayıcısı"
  },
  partnerCalculatorBlock: {
    country: "Ülke",
    priceSim: "SIM kartın maliyeti",
    countPort: "Modemdeki port sayısı",
    netProfit: "Kâr",
    netProfit3: "3 aylık kâr",
    netProfit6: "6 aylık kâr",
    netProfit12: "12 aylık kâr"
  },
  generalSearch: {
    searchByCountry: "Ülkeye göre arama",
    searchByService: "Hizmete göre arama",
    title: "Sms almak için sanal numaralara ilişkin fiyatlar",
    subtitle: "Aşağıdaki tablodan ihtiyacınız olan hizmeti veya ülkeyi seçin",
    tableHeaderService: "Hizmet",
    tableHeaderCountry: "Ülke",
    tableHeaderCurrency: "Toptan fiyat",
    tableHeaderAmount: "Miktar"
  },
  profileLoyaltyProgram: {
    cashback: {
      title: "Cashback",
      week_deposit: "Mevcut hafta için yükleme tutarı",
      projectedCashback: "Tahmini cashback",
      tillNextRank: "Sonraki dereceye kalan",
      cashbackPercentNextRank: "Bir sonraki derecede cashback",
      rang: "derece"
    },
    history: {
      title: "Katılım geçmişi",
      message1: "Yükleme karşılığında",
      message2: "verilen cashback"
    },
    programConditions: {
      title: "Sadakat programı koşulları",
      rangLeft: "Derece: % cash back",
      rangRight: "alma koşulu",
      rangNotLeft: "Derecesiz: Cashback olmadan > 0 <1 000 ₽ ",
      rangNotRight: "yükleme",
      message: "Cashback, bir önceki hafta için toplam yükleme miktarının sonuçlarına göre haftanın <span>1. günü (Pazartesi)</span> kullanıcılara yatırılır. Cashback yüzdesi, kullanıcıların bir önceki haftanın sonunda ulaştıkları dereceye bağlıdır.",
      replenishment: "yükleme",
      cashback: "Cashback"
    }
  },
  license: {
    licenseSuccess: "Lisans satın alındı ve kayıt e-postasına gönderildi",
    buyKey: "GrizzlySMS için anahtar satın alın"
  },
  featuresTextBlock: {
    getIndividualNumber: "Özel numara al"
  },
  sortComponent: {
    country: "Başlıca ülkeler"
  },
  rentNumbers: {
    typeViews: {
      "number_activation": "Numara aktivasyonu",
      "rent_number": "Numara kiralama",
      "readyAccounts": "Hesaplar"
    },
    rentInformation: {
      "rental_period": "Kiralama süresi",
      "rental_duration": "Kiralama süresinin uzunluğunu",
      "rent": "Kirala",
      "the_clock": "saatliğine",
      "for_days": "günlüğüne",
      "specify_rental_duration": "Kiralama süresinin uzunluğunu belirtin",
      "maximum_number_hours": "Maksimum saat sayısı",
      "minimal_number_hours": "Minimum saat sayısı",
      "number_successfully_rented": "Numara başarıyla kiralandı",
      "selected_time_not_available": "Numara mevcut değil",
      "available_minimum_rental_period": "Mevcut minimum kiralama süresi",
      "available_maximum_rental_period": "Mevcut maksimum kiralama süresi"
    },
    myRentNumbers: {
      "rent_virtual_number": "Sanal numara kiralama",
      "active": "Aktifler",
      "received": "Alınanlar",
      "returned": "İptal edilenler",
      "not_found": "Kiralık numaranız yok"
    },
    rentNumberCard: {
      "canceled": "İptal edilen",
      "expires_in": "Süresinin dolmasına:",
      "extension": "Uzatma",
      "messageTitle": "Mesajlar",
      "messageMessage": "Henüz herhangi bir mesaj veya arama gelmedi. Numaraya mesaj atın veya arayın, bilgiler hemen burada gösterilecektir.",
      "successCancel": "Numara kiralama başarıyla iptal edildi",
      "successNumberRenewal": "Numara kiralama başarıyla uzatıldı",
      "rentalPeriodExpired": "Kiralama süresi doldu"
    },
    readyNumbers: {
      message: 'ÖNEMLİ! Başlangıçta hazır hesap satın alıyorsanız, "Destek (SSS)" sayfasındaki <a href="{link}"> "Hesaplar"</a> bölümündeki bilgileri incelemeniz gerekecektir.',
      ok: 'Kabul ediyorum',
      toFaq: 'SSS',
      cancel: 'İptal etmek'
    },
    popUpExtendLease: {
      "extend_number_rental": "Numara kiralamayı uzat",
      "going_renew_lease_number": "Numaranın kiralama süresini uzatacaksınız.",
      "renewal_history": "Uzatma geçmişi",
      "date": "Tarih",
      "price": "Ücret",
      "term_hours": "Saat cinsinden süre",
      "number_rental_renewal_period": "Oda kiralama uzatma süresi",
      "lease_successfully_extended_until": "Kira başarıyla şuna uzatıldı:",
      "close": "Kapat",
      "short_day": "gün",
      "short_hours": "sa.",
      "short_minutes": "dk."
    },
    helpPopUp: {
      message: `<h4>Numara etkinleştirme</h4>
         <p>İlk 20 dakika içinde kod içeren bir SMS almazsanız numarayı iptal edebilirsiniz, paranın tamamı hesabınıza iade edilecektir.</p>

         <h4>Numara kiralama</h4>
         <p>Seçilen dönem boyunca seçilen hizmetten her zaman sınırsız sayıda SMS alma fırsatına sahip olacaksınız.</p>
         <p>Kayıtlı hesabınıza erişimi istediğiniz zaman geri yükleyebilirsiniz!</p>
         <p>İlk 20 dakika içinde kod içeren bir SMS almazsanız numarayı iptal edebilirsiniz, paranın tamamı hesabınıza iade edilecektir.</p>
         <p>SMS almadıysanız ve üzerinden 20 dakikadan fazla süre geçtiyse kiralamayı iptal etmeniz mümkün olmayacaktır.</p>
         <p>Kira için satılan bir numara, kiralama süresi sona erdiğinde ilgili hizmet için yeniden satılmayacaktır.</p>
         <p>Minimum kiralama süresi 4 saat, maksimum 4 haftadır.</p>
         <p>Numara kiralamak için iki seçenek vardır:</p>
         <p>Tam kiralama - bu durumda hizmetleri kataloğumuzda sunulan tüm SMS'leri alabilirsiniz.</p>
         <p>Belirli bir hizmet için numara kiralayın. Bu durumda yalnızca seçtiğiniz hizmetten SMS alacaksınız.</p>

         <h4>Hesaplar</h4>
         <p>SMS bekleyerek zamanınızı boşa harcamak istemiyor musunuz? Ortaklarımızın hazırladığı hazır bir hesabı satın alabilirsiniz. Telegram, VK ve diğer hizmetlere yönelik hesaplar genellikle mevcuttur.</p>
         <p>Telegram hesapları 2 formatta sağlanır: TDATA (bu formattaki bir hesap bir PC'de oturum açabilir ve daha sonra bir mobil cihaza aktarılabilir) ve json+session (bu durumda özel bir yazılıma ihtiyacınız olacaktır).</p> p>
         <p>Hazır hesaplarla çalışmanın detayları ve iade koşulları SSS bölümünde (site menüsündeki “Destek” butonu) açıklanmıştır. Satın almadan önce bu bilgileri okuduğunuzdan emin olun!</p>
      `
    }
  },
  comments: {
    "add_comment": "Yorum ekle",
    "comments": "Yorumlar",
    "collapse": "Daralt",
    "expand": "Genişlet",
    "send_comment_moderation": "Yorumunuz moderasyona gönderildi.",
    "ony_auth_user": "Sadece kayıtlı kullanıcılar için mevcuttur"
  },
  saleAgreement: {
    "title": "Lisans satışı",
    "name": "Ad",
    "type_po": "Yazılım tipi",
    "price": "Ücret",
    "date_purchase": "Satın alma tarihi"
  },
  freeNumbersPopUpRegistration: {
    subtitle: "Ücretsiz bir numara ile hesap oluşturamadınız mı? Kaydolun ve istediğiniz hizmet için kişiselleştirilmiş bir numara seçin; <span>1.10 ₽ itibarıyla!</span>",
    button: "DENİYORUM"
  },
  accountShop: {
    mainSectionInfo: {
      my_paid_accounts_title: "Ücretli hesaplarım",
      my_paid_accounts_message: "Burada şimdiye kadar satın aldığınız tüm hesapların bir listesini ve gerekli giriş bilgilerini görebilirsiniz",
      my_paid_accounts_button: "Hazır hesapların satın alınması",

      purchasing_ready_accounts_title: "Hazır hesapların satın alınması",
      purchasing_ready_accounts_message: "20 dakika içinde SMS kabul etmenin her zaman mümkün olmadığını anlıyoruz. Bu bölümde, herhangi bir popüler hizmet için hazır bir hesap satın alabilirsiniz.",
      purchasing_ready_accounts_button: "Ücretli hesaplarım"
    },
    selectedService: {
      title: "Hizmet seçin",
      search_placeholder: "Hizmet adına göre arama"
    },
    selectedOffer: {
      title: "Teklif seçin"
    },
    dialogOffer: {
      title: "Hazır hesap satın al",
      name_of_product: "Ürün adı",
      description_of_product: "Ürün açıklaması",
      characteristics: "Özellikler",
      registration_method: "Kayıt yöntemi",
      country: "Ülke",
      account_format: "Hesap biçimi",
      availability_cloud_password: "Bulut şifresinin kullanılabilirliği",
      information_about_purchased_product: "Satın alınan ürünle ilgili veriler",
      download_files: "Dosyaları indir",
      buy_more: "Daha fazla satın al",
      yes: "Evet",
      no: "Hayır",
      minimum_order_quantity: "Minimum sipariş miktarı"
    },
    myServices: {
      messageAuth: "Hesabınıza giriş yapmanız gerekmektedir",
      messageEmpty: "Satın alınan hesap yok"
    }
  },
  partner: {
    breadcrumbs_title: "Partner",
    documentation: "Dokümantasyon",
    activations_history: {
      activations_history: "Aktivasyon geçmişi",
      filter: "Filtrele",
      export_in: "Şuna aktar:",
      number: "Numara",
      load_more: "Daha fazla göster",
      total_rows: "Toplam: {{ count }} kayıt",
      select_status: "Durum seç",
      date_activation: "Aktivasyon tarihi",
      date_from: "Başlangıç tarihi",
      date_to: "Bitiş tarihi",
      ready: "Tamamlandı",
      canceled: "İptal edildi",
      activation: "Aktivasyon (sms mevcut)"
    },
    sim_card: {
      sim_card: "SIM kartlar",
      port: "Port",
      info: "Bilgi",
      earned: "Kazanılan",
      countServices: "Hizmet sayısı"
    },
    price_regulator: {
      price_regulator: "Fiyat düzenleyici",
      select_country_started: "Başlamak için ülke seçin",
      services: "Hizmetler",
      search_service: "Hizmete göre arama",
      all_services: "Tüm hizmetler",
      popular_services: "Popülerler",
      market_price: "Set market price",
      competitors_prices: "Show competitor prices",
      pice_successfully_changed: "Fiyat başarıyla değiştirildi"
    },
    withdrawal_founds: {
      withdrawal_founds: "Para çekme",
      balance: "Bakiyeniz",
      order_payments: "Ödeme talimatı ver",
      currency_type: "Para birimi tipi",
      withdrawal_method: "Para çekme yöntemi",
      order_status: "Başvuru durumu",
      select_method: "Yöntem seç",
      select_status: "Durum seç",
      requisites: "Bilgiler",
      sum: "Tutar",
      currency: "Para birimi",
      date: "Tarih",
      status: "Durum",
      nothing_found: "Hiçbir şey bulunamadı",
      amount_in_currency: "Para birimi cinsinden tutar",
      wallet_card_number: "Hesap / kart numarası",
      comment: "Yorum",
      not_necessary_placeholder: "İsteğe bağlı",
      all_btn: "Tümü",
      error_required: "Zorunlu tüm alanları doldurun",
      success_msg: "Başvurunuz kabul edildi",
      warning_message: "<span>ÖNEMLİ!</span> Lütfen yukarıdaki başvuruyu oluştururken girilen ödeme bilgilerinin doğru olduğundan emin olun. grizzlysms.com yönetimi, yanlış ödeme bilgileri nedeniyle para kaybı durumunda sorumlu değildir."
    },
    statistic: {
      statistic: "Parasallaştırma istatistikleri",
      average_SIM: "SIM kart başına ortalama",
      top_services: "En iyi hizmetler",
      activations_count: "24 saat içindeki aktivasyon sayısı",
      last_24_hours: "Son 24 saat içinde"
    },
    dialog_competitors_prices: {
      your_price: "Senin fiyatin",
      competitors_prices: "Rakiplerin fiyatları"
    },
  },
  newPayments: {
    minAmount: "Minimum ödeme:",
    commission: "Komisyon:"
  },
  soft: {
    "submit_application": "Başvuruyu Gönder",
    "search_by_name": "İsme göre ara",
    "all_services": "Tüm<br/>hizmetler",
    "activations": "Aktivasyonlar"
  },
  productLicensesBlock: {
    availableAt: "Available at",
  },
  documentationApi: {
    rent: "API rent",
    tables: "Tables",
    activations: "API activations",
    expandContent: "Expand content",
    collapseContent: "Collapse content",
    successLinkCopy: "Link copied successfully",
    successApiKeyCopy: "API Key copied successfully",
  },
  marketplaceRegistrationPage: {
    pageTitle: "Mağaza kayd",
    formInputs: {
      name: {
        label: 'Mağazanız için bir isim bulun. Belki de adlandırdığınız şey, ürününüzün özünü yansıtacak ve alıcıya seçim yaparken yardımcı olacaktır.',
        placeholder: 'İsim',
        errorMessage: 'Doldurmak için gerekli'
      },
      shopDescription: {
        label: 'Mağaza Açıklaması',
        placeholder: 'Mağazanın bir tanımını yapın, benzersizliğini ve özelliklerini vurgulayın...',
        errorMessage: 'Doldurmak için gerekli'
      },
      email: {
        label: 'E-postanız',
        placeholder: 'Geri bildirim için lütfen e-posta adresinizi giriniz...',
        errorMessageForNull: 'Doldurmak için gerekli',
        errorMessageNoValid: 'Geçersiz e-posta adresi'
      },
    },
    formSendButton: 'Bir mağazayı kaydedin',
    rulesMarket: {
      iAgree: 'Kabul ediyorum',
      rulesPhrase: 'Grizzlysms.com pazar yeri kuralları ve düzenlemeleri'
    },
    offerAgreement: {
      iAccept: 'Kabul ediyorum',
      offerPhrase: 'teklif anlaşması'
    },
    toastSuccessMessage: 'Başvurunuz gönderildi'
  }
}
