import dictionaryRuEn from "~/constant/dictionary-ru-en.json";
import dictionaryEnRu from "~/constant/dictionary-en-ru.json";

export const state = () => ({
  services: [],
  languages: [],

  activeServiceId: null,
  activeServiceIdExternal: null,
  activeLanguageId: null,

  typeNumberReceiving: "buy", // buy | rent

  isWholesale: false,
  isLoadServices: true,
  isLoadLanguages: true,
  isLoadTypeNumberReceiving: false
});

export const mutations = {
  setListServices(state, services) {
    state.services = services;
  },
  setListLanguages(state, languages) {
    state.languages = languages;
  },

  setActiveServiceId(state, activeServiceId) {
    state.activeServiceId = activeServiceId;
  },
  setActiveServiceIdExternal(state, activeServiceIdExternal) {
    state.activeServiceIdExternal = activeServiceIdExternal;
  },
  setTypeNumberReceiving(state, val) {
    state.typeNumberReceiving = val;
  },

  setLoadServices(state, isLoadServices) {
    state.isLoadServices = isLoadServices;
  },
  setLoadLanguages(state, isLoadLanguages) {
    state.isLoadLanguages = isLoadLanguages;
  },
  setIsWholesale(state, isWholesale) {
    state.isWholesale = isWholesale;
    this.$cookies.set("isWholesale", isWholesale);
  },
  setLoadTypeNumberReceiving(state, isLoadTypeNumberReceiving) {
    state.isLoadTypeNumberReceiving = isLoadTypeNumberReceiving;
  },
};

export const actions = {
  getServicesList: async function ({commit, state, dispatch}) {
    commit("setLoadServices", true);

    const _getTranscriptionRu = (dictionary, word = '') => {
      let _arr = word.split('');
      _arr = _arr.map((t) => dictionary[t]);
      return _arr.join('')
    }

    let services = await this.$api.agent.transport.get("/service/get-all").then((res) => {
      return res.data
    }).catch(() => {
      return []
    });
    if (state.typeNumberReceiving === 'rent') {
      services = await this.$api.agent.transport.get(`/rent/get-services`).then((res) => {
        return res.data
      }).catch(() => {
        return []
      })
    }

    services = [...(services || [])].map((service) => {
      const name = String(service?.name || '').toLocaleLowerCase();
      return {
        ...service,
        nameTranscriptionRuEn: _getTranscriptionRu(dictionaryRuEn, name),
        nameTranscriptionEnRu: _getTranscriptionRu(dictionaryEnRu, name),
      }
    });

    commit("setLoadServices", false);
    commit("setListServices", services);
    await dispatch("getLanguagesList");
  },

  getLanguagesList: async function ({commit, state, rootState}, payload) {
    commit("setLoadLanguages", true);

    const userId = rootState?.user?.user?.id ? rootState?.user?.user?.id : 'guest';
    const isWholesale = Number(state.isWholesale);

    let actionUrl = `/country/get-prices/${state.activeServiceId}?page=1&wholesale=${isWholesale}&user=${userId}`;
    if (state.typeNumberReceiving === 'rent') {
      actionUrl = `/rent/get-prices?service_id=${state.activeServiceIdExternal}&wholesale=${isWholesale}&user=${userId}`
    }

    const response = await this.$api.agent.transport
      .get(actionUrl)
      .then((res) => {
        return res.data
      }).catch(() => {
        return []
      })

    commit("setListLanguages", response);
    commit("setLoadLanguages", false);
    commit('setLoadTypeNumberReceiving', false);
  },

  initActiveServiceId: async function (params) {
    const {commit, rootState} = params;

    let activeServiceId = this.$cookies.get("selectedServiceId");
    if (activeServiceId === undefined) {
      activeServiceId = null;
    }
    if (activeServiceId === null) {
      activeServiceId = Number.parseFloat(rootState.appState?.vars?.["Сервис выбора по умолчанию"]);
    }

    commit("setActiveServiceId", activeServiceId)
  },
  initActiveLanguageId: async function ({commit}) {
  },
  initIsWholesale: async function ({commit}) {
    let isWholesale = this.$cookies.get("isWholesale");
    if (isWholesale === undefined) {
      isWholesale = false
    }
    commit("setIsWholesale", isWholesale);
  },
  initTypeNumberReceiving: async function ({commit}) {
    const isActive = this.app?.context?.env?.['activeRentFunctional'] || "0";
    const isShowRent = Boolean(String(this.$cookies.get("show-rent") || "0") === "1");
    const typeNumberReceiving = this.$cookies.get("type-number-receiving");
    const value = Boolean((isActive === "1" || isShowRent)) ? typeNumberReceiving || "buy" : "buy";
    commit('setTypeNumberReceiving', value);
  },
  changeTypeNumberReceiving: async function ({commit, dispatch}, val) {
    if (val === 'readyAccounts') {
      commit('setTypeNumberReceiving', val);
      return;
    }

    commit('setLoadTypeNumberReceiving', true);
    commit('setTypeNumberReceiving', val);
    await dispatch("getServicesList");
    this.$cookies.set("type-number-receiving", val);
  },
};
