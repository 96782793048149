export const state = () => ({
  currentCurrency: 'rub',
  currencyConvertValue: null,
})

export const mutations = {
  setCurrency(state, currency) {
    state.currentCurrency = currency;
  },

  setCurrencyConvertValue(store, value) {
    store.currencyConvertValue = value;
  }
}
export const actions = {
  async setCurrencyConvertValue({commit}) {
    try {
      const response = await this.$api.agent.transport
        .get(`currency/get-usd-rate`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return null;
        });
      if (response) {
        commit('setCurrencyConvertValue', response);
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export const getters = {
  currentCurrency: ({currentCurrency}) => currentCurrency,
  currencyConvertValue: ({currencyConvertValue}) => currencyConvertValue,
}
