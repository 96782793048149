export default {
  header: {
    profile: "个人账户",
    loginButton: "登录",
    registrationBtn: "注册账户",
    feedback: "联系我们",
    languageToggle: {
      en: "英文",
      ru: "俄语",
      cn: "中文",
      tr: '土耳其语'
    }
  },
  profileNavMenu: {
    goToProfile: "进入个人账户",
    activeNumber: "已收到的电话号码",
    queryStatistics: "查询记录",
    balanceHistory: "账户流水",
    tariffs: "费率",
    settings: "设置",
    balance: "账户余额",
    topupBalance: "充值",
    topupAlert: "",
    logout: "推出",
    referralProgram: "合作伙伴计划",
    saleAgreement: "Sale of licenses",
  },
  profile: {
    activeNumbers: {
      title: "已收到的电话号码",
      noActiveNumbers: '',
      serverError: '服务器错误',
      status: "动态",
      waitingForSms: "正在等待短信",
      time: "时间",
      banned: "被拦截了码？",
      cancel: "取消",
      message: '代码已接收并已付',
      messageMultipleSms: '剩下的时间内您可以免费收该服务项下的新代码',
      blockModalMessage1: '改电话号码被发验证码的网站屏蔽了吗<br />如果是这样，请告诉我们。我们将取掉发放该电话号。',
      blockModalMessage2: '资金会立刻退换到您的个人账户上。<br />试一下再买这个国家或者另外一个国家的新电话号码',
      blockBtn: '是的，电话号被屏蔽了',
      moreSms: "",
      availableIn: "即将生效: ",
      waitingSms: "在等待接收短信"
    },
    queryStatistics: {
      title: "查询记录",
      noQuery: "没有查询",
      buyFor: "……购买",
    },
    balanceHistory: {
      title: "账户流水",
      outgoing: "开支记录",
      incoming: "充值记录",
      showMore: "显示更过",
      noHistory: "没有记录",
      numberOrder: "……号付款单项下的付款",
    },
    tariffs: {
      title: '费率',
    },
    settings: {
      title: "设置",
      sound: "收验证码时播放声音",
      infoAboutServices: "显示正在进行的购买信息",
      twoFactorEnabled: "双重验证",
      infoAboutBalance: "余额底线邮件通知",
      apiKey: "API代码",
      message: "请形成API代码，便于通过API和网站互动",
      buttonUpdate: "更新API代码",
      updateError: "更新错误",
      settingsChanged: "设置已更改",
      errorChangingSettings: "设置更改错误",
      phoneNumber: "您的电话号码",
      playceholderPhone: "请输入您的电话号码",
      refParams: {
        refLink: "合作伙伴计划的报告：",
        refOptions: "合作伙伴计划设置：",
      },
    },
    myPersonalInformation: {
      title: "您的个人信息",
      yourEmail: "邮箱",
      yourName: "名字",
    },
    referralProgram: {
      title: "合作伙伴计划",
      referralLinkTitle: "合作伙伴计划链接",
      buttonCopy: "复制",
      buttonDownload: "下载",
      topUpAmount: "您的合作伙伴消费",
      youHaveAlreadyReceived: "合作伙伴计划项下的收款",
      awaiting: "合作伙伴计划余额",
      detailing: "合作伙伴计划系统的报告",
      derived: '其中已提取',
      formTitle: "提交申请",
      formButton: "提交提取申请",
      participatePromotion: "Participate in the promotion"
    }
  },
  profilePay: {
    sectionBalanceReplenishment: {
      title: "充值",
      cardButtonSelect: "选择",
      paymentTitle: "确认支付",
      paymentMessage: "一定要通过网站界面进行付款。只有通过点击付款按钮您才能将钱转至正确的帐号上。",
      paymentMessageAlipay: "The service only supports users in Chinese Mainland Verified. You can try a method for all AliPay users below.",
      paymentPlaceholder: "请输入金额 *",
      paymentButton: "付款",
      paymentErrorAmount: "充值金额一定要大于零",
      payComment: "充值手续费",
      plasticCard: "银行卡：仅限于俄罗斯  🇷🇺",
      minAmount: "最低付款金额{minPrice}俄罗斯卢布",
      minAmountMessage: "最低付款金额{minPrice}俄罗斯卢布",
      payMessageForPayeer: "选择该充值方式，grizzlysms.com服务将由THREETWOTWO LTD公司提供。为了继续，您必须同意下列文件。",
      payMessageForPaypalych: "Bank cards: only Russia  🇷🇺 (alt №3)",
      som: 'Bank cards: all over the world — method №2',
      payMessageForNut: "Bank cards: all over the world — method №3",
      stripe: "Bank cards: all over the world — method №1 (Stripe)",
      bankCardsTemplateRussia: "Bank cards: only Russia  🇷🇺 (alt №2)",
      payMessageForTether: "注意：汇款款金额要跟支付系统形成的金额一直。不然资金入不了账户，而退款费用是10美金",
      alipayTitle: "用支付宝付款请扫码：",
      alipayText: "...请给在线客服发一下您注册的时候用的邮箱，添加付款凭证的截图（↘️）<br/>余额无法自动充值。<br/>最低充值金额20人民币。",
      paymentMessageForConvertor: "付款金额, {currency}:",
      paymentMessageForConvertorWithCommission: "含手续费的付款金额, {currency}:",
      willBeCredited: "充值金额, {currency}:",
      messageForConverterDollars: "We accept dollars and convert them into rubles at the rate: <span> {rate} </span> ₽ per dollar.",
      getNumbersFor: 'Getting number for {name}',
      usdHelpMessage: '仅供参考。请优先考虑付款工具界面上显示的金额。由于汇率波动，金额可能略有不同。'
    },
    aliPay: {
      title: "支付宝和QQ支付",
      messageStep1: "1、点击下面的链接，选择充值余额，然后用支付宝或QQ支付:",
      messageStep2: "2、为了充值余额，请输入支付之后收到的卡密:",
      send: "发送",
      couponNotFound: "找不到優惠券",
      placeholderPassword: "卡密",
      waitingForRedirectPayment: "等待過渡到網站進行付款",
      paymentCodeNotCorrect: "支付碼不正確",
      buttonGetLink: "用支付宝或QQ支付，并接验证码",
    },
    cryptoPayments: {
      label: "Crypto payments"
    }
  },
  servicesList: {
    "Выбор_страны": "選擇 {name} 的國家/地區",
    title: "请选择国家",
    titlePopular: "受欢迎的国家",
    chooseAnother: "请选择另外一个国家",
    countryInputPlaceholder: "请选择国家",
    selectedLable: "选择",
    searchInputPlaceholder: "服务搜索",
    buttonWaiting: "稍等一下……",
    buttonBuy: "收",
    buttonRent: "Rent",
    message: '暂时没有该国家的电话号',
    buttonUpdate: '更新',
    quantity: '个',
    quantityFew: '缺货',
    numberTemporarilyUnavailable: "这些数字只能通过API获得",
    orderFrom100numbers: "购买超过100个号码联系客服",
    labelCountryHighPercentDelivery: "接码比例比较高的国家",
    labelSelectOtherCountry: "选择别的国家",
    showMore: "显示更多",
    from: '起',
    retail: '零售价',
    authRetail: '您的报价',
    wholesale: '批发',
    telegramMessagePrice: '批发价频道:',
    telegramMessageBot: 'TG小应用:',
    telegramMessageNews: '本站最新消息:',
    scrollBtn: '所有的服务和国家',

    requestNewServiceForm: {
      title: '添加新服务项目',
      subTitle: '没找到合适的服务项目？请告诉我们更详细一点，我们将会进行更改。<br />  我们建议你马上使用这个栏目。',
      anyother: '其它',
      dataAboutSite: '网站地址 *',
      smsMessage: '短信内容 *',
      countryService: '期望的国家（可选）',
      price: '电话吗价格（可选）',
      btnSend: '发送',
      backToList: '查清单',
      offerServiceBtn: "推荐服务项目",
    },
    linkToAccount: {
      btnMsg: "{accountName} accounts",
      text: "Working with ready-made {accountName} accounts requires PC skills; the set of available countries differs from the set of countries for self-registration of accounts"
    }
  },
  languagesList: {
    "Выбор_сервиса": "选择服务",
    "Поиск_по_сервисам": "Search by services",
    "Выберите_услугу": "请选择服务",
    "Поиск_по_странам": "按国家搜索",
    "Успешная_покупка_номера": "号码已购买成功。该电话号码即将显示在已收到的电话号码项目里面。",
    serviceNotFound: 'Service not found',
    repeatMsg: 'wait < {min} min',
    repeatModal: {
      attempt: 'Attempt'
    },
    errorMessages: {
      "NO_NUMBERS": "该号码不可用。请再试"
    }
  },
  blog: {
    title: '博客',
    buttonDetails: "了解更多",
    pageNumber: "页码",
    readMore: 'Similar articles'
  },
  footer: {
    title: "最好的在线短信接码平台。",
    userAgreement: "公开报价",
    referralProgram: "合作伙伴计划",
    returnsPolicy: "退款政策",
    personalInformation: "隐私和Cookie政策",
    messageDisabledRussianNumbers: "来自俄罗斯的电话号码不出售",
    footerDisabledRussianNumbers: "Subscriber numbers of Russian mobile operators are not provided for temporary use due to the requirements of the Federal Law \"On Communications\" dated 07.07.2003 N 126-FZ and also on the basis of subparagraph g of paragraph 5 of the Rules for the centralized management of the public communications network, approved by Decree of the Government of the Russian Federation dated February 12, 2020 N 127 \"On approval of the Rules for the centralized management of the public communications network\""
  },
  registration: {
    title: '注册账户',
    registrationSuccessMessage: "用户注册成功！确认注册的链接已发送到邮箱",
    nameInputPlaceholder: '名字 *',
    emailInputPlaceholder: '邮箱 *',
    passwordInputPlaceholder: '密码 *',
    passwordConfirmInputPlaceholder: '确认密码 *',
    buttonRegistration: '注册',
    buttonRegistrating: '稍等一下……',
    passwordsMismatchMessage: "两次密码不一致",
    backHome: '放回首页',
    loginWithSocialNetworks: "登录方式：",
    orRegistrartion: "或注册",
    iAgree: "我了解并接受",
    privacyPolicy: "隐私和Cookie政策",
    userAgreement: "公开报价服务条款",
    alreadyHaveAccount: "已有账户吗？",
    login: "登录",
    messageSuccessConfirmRegistration: "用户注册成功",
  },
  authorization: {
    title: "登录",
    emailInputPlaceholder: '邮箱',
    passwordInputPlaceholder: '密码',
    tokenInputPlaceholder: '验证码',
    buttonRegistration: '登录',
    buttonRegistrating: '稍等一下……',
    or: 'или',
    newUser: '新用户吗？',
    registration: '这册',
    forgotPassword: "忘记密码？",
  },
  resetThePassword: {
    title: "找回密码",
    buttonReset: '找回密码',
    buttonWaiting: '稍等一下……',
    emailInputPlaceholder: '邮箱',
    message: "找回密码的链接已发送至您邮箱。请检查您的邮箱并按照邮箱里面的说明进行操作。"
  },
  errorsValidationForms: {
    requiredForFill: "必填",
    emailErrorMessage: "邮箱不正确",
    requaredLengthMessage: "最少字符数："
  },
  advantagesBlock: {
    title: ""
  },
  aboutBlock: {
    showMoreBtn: "显示更多",
    rollUp: "最小化",
  },
  serviceCard: {
    availableNumbers: "现有的电话号",
    price: "价格",
    buyNow: "收",
    emptyCountryListMessage: "未找到该项目电话号码.<br /> 稍后再试"
  },
  feedbackForm: {
    title: "联系我们",
    namePlaceholder: "名字 *",
    emailPlaceholder: "邮箱 *",
    messagePlaceholder: "您的信息 *",
    iAgree: "我了解并接受",
    privacyPolicy: "隐私和Cookie政策",
    userAgreement: "公开报价服务条款",
    buttonSend: '发送',
    buttonRegistrating: '稍等一下……',
    sendError: "发送错误",
    applicationIsAccepted: "您的申请已收到"
  },
  copyToBuffer: {
    success: "内容复制成功",
    error: "复制错误",
  },
  breadcrumbs: {
    homePage: "首页",
    blog: "博客",
  },
  confirm: {
    passwordReset: "找回密码",
    newPassword: "新密码",
    confirmPassword: "确认密码",
    confirm: "确认",
    authorization: "登录",
    notMatch: "两次密码不一致",
    changedSuccessfully: "密码更改更攻",
  },
  errorPage: {
    pageNotFound: '未找到页面',
    somethingWentWrong: '出了点问题',
    backHomepage: '放回首页'
  },
  support: {
    inputSearch: {
      placeholder: "输入你的问题",
    }
  },
  successPayment: {
    successMsg: "付款成功",
    waitMsg: "Payment is in progress",
    backHomepage: '首页'
  },
  searchPage: {
    placeholder: '搜索',
    showMore: "显示更多",
    nothingFoundMsg: "未找到适合查询的信息",
    'Статьи': "文章",
    'Страницы': "页面",
    'Все результаты': "所有的查询结果",
  },
  common: {
    "downloadFile": "文件下载"
  },

  lastArticlesBlock: {
    title: "博客",
    textBtn: "博客",
  },

  reviewsBlock: {
    title: '用户评价',
    addReviewBtn: '留评',
    yourName: '名字',
    messageTextarea: '请您留评',
    sendReview: '提交评价',
    success: '评价提交成功',
    validNameMsg: '名字',
    validMsg: '输入信息',
  },

  currencyToggle: {
    tooltipCurrency: '美元报价仅供参考。账户结算一律使用卢布进行。'
  },

  pagination: {
    showing: '显示',
    of: 'из',
  },

  additionalCashbackAccrual: {
    message: "第一次充值大于1000卢布收 <span>加10%</span> 回款 <span>{{balance}}</span> !",
    buttonCancel: "充值",
    buttonPayment: "充值"
  },

  countries: {
    title: '可用的国家',
    countries: '国家',
    popularServices: '热门的服务',
    anotherServices: '请选择服务'
  },

  partnerPage: {
    becomePartner: "成为合作伙伴",
    popUp: {
      title: "成为合作伙伴",
      yourContactPlaceholder: "您的聯繫人（電報、微信、WhatsApp、電子郵件、其他）",
      countryPlaceholder: "SIM 卡的“原產國”",
      equipmentPlaceholder: "你的設備",
      equipmentPortsPlaceholder: "硬件端口數",
      aboutPlaceholder: "關於您，我們還需要了解什麼？",
      send: "發送",
      accept1: "我同意",
      accept2: "隱私政策和所請求數據的使用",
      errorForm: "發生了一個錯誤。請稍後重試",
      successForm: "申請成功發送",
    }
  },

  partnerFormBlock: {
    title: "Become a partner",
    contactTitle: "Phone, Telegram, WeChat, WhatsApp, E-mail",
    contactPlaceholder: "Your preferred contact",
    countrySimCard: "Countries of \"origin\" of SIM cards",
    countrySimCardPlaceholder: "Comma separated",
    yourEquipment: "Your Equipment",
    yourEquipmentPlaceholder: "Tell me in detail",
    countPort: "Number of hardware ports",
    countPortPlaceholder: "Specify in numbers",
    message: "What else do we need to know about you?",
    messagePlaceholder: "Describe your business in detail",
  },
  partnerHeadingBlock: {
    becomePartner: "Become a partner",
    yieldCalculator: "Yield Calculator"
  },
  partnerCalculatorBlock: {
    country: "Country",
    priceSim: "SIM card cost",
    countPort: "Number of ports in the modem",
    netProfit: "Profit",
    netProfit3: "Profit for 3 months",
    netProfit6: "Income for 6 months",
    netProfit12: "Income for 12 months",
  },
  generalSearch: {
    searchByCountry: "Search by country",
    searchByService: "Search by service",
    title: "Prices for virtual numbers for receiving SMS",
    subtitle: "Select the service or country you need in the table below",
    tableHeaderService: "Service",
    tableHeaderCountry: "Country",
    tableHeaderCurrency: "Wholesale price",
    tableHeaderAmount: "Quantity",
  },
  profileLoyaltyProgram: {
    cashback: {
      title: "Cashback",
      week_deposit: "Top-up amount for the current week",
      projectedCashback: "Predicted cashback",
      tillNextRank: "Until the next rank left",
      cashbackPercentNextRank: "Cashback in the next rank",
      rang: "rank"
    },
    history: {
      title: "Participation history",
      message1: "For replenishment",
      message2: "cashback credited",
    },
    programConditions: {
      title: "Loyalty program terms",
      rangLeft: "Rank: obtaining condition",
      rangRight: "% cashback",
      rangNotLeft: "No rank: top-ups > 0 <1,000 ₽",
      rangNotRight: "no cashback",
      message: "Cashback is credited to users on <span>1st day of the week (Monday)</span> based on the results of the total amount of deposits over the past week. The percentage of cashback depends on the rank achieved by users by the end of last week.",
      replenishment: "replenishment",
      cashback: "Cashback"
    }
  },
  license: {
    licenseSuccess: 'The license has been purchased and sent to your email',
    buyKey: 'Buy a key on GrizzlySMS'
  },
  featuresTextBlock: {
    getIndividualNumber: "获取一个私人号码"
  },
  sortComponent: {
    country: 'Top countries'
  },
  rentNumbers: {
    typeViews: {
      "number_activation": "激活",
      "rent_number": "出租",
      "readyAccounts": "Accounts"
    },
    rentInformation: {
      "rental_period": "Rental period",
      "rental_duration": "Rental duration",
      "rent": "Rent",
      "the_clock": "by hour",
      "for_days": "by day",
      "specify_rental_duration": "Specify rental duration",
      "maximum_number_hours": "Maximum number of hours",
      "minimal_number_hours": "Minimum number of hours",
      "number_successfully_rented": "Number successfully rented",
      "selected_time_not_available": "Number unavailable",
      "available_minimum_rental_period": "Available minimum rental period",
      "available_maximum_rental_period": "Available maximum rental period",
    },
    myRentNumbers: {
      "rent_virtual_number": "Rent a virtual number",
      "active": "Active",
      "received": "Received",
      "returned": "Canceled",
      "not_found": "Not found"
    },
    rentNumberCard: {
      "canceled": "Canceled",
      "expires_in": "Expires in",
      "extension": "Extend",
      "messageTitle": "Messages",
      "messageMessage": "No messages or calls have arrived yet. Send an SMS to the number or call, and the information will immediately be shown here.",
      "successCancel": "Number rental successfully canceled",
      "successNumberRenewal": "Number rental successfully extended",
      "rentalPeriodExpired": "Expired"
    },
    readyNumbers: {
      message: 'IMPORTANT! If you are initially purchasing ready-made accounts, you will need to review the information in the <a href="{link}"> "Accounts"</a> section on the "Support (FAQ)" page.',
      ok: 'I agree',
      toFaq: 'FAQ',
      cancel: 'Cancel'
    },
    popUpExtendLease: {
      "extend_number_rental": "Extend number rental",
      "going_renew_lease_number": "Are you going to renew your lease on a number?",
      "renewal_history": "Renewal history",
      "date": "Date",
      "price": "Price",
      "term_hours": "Time limit in hours",
      "number_rental_renewal_period": "Number rental renewal period",
      "lease_successfully_extended_until": "The lease was successfully extended until",
      "close": "Close",
      "short_day": "days",
      "short_hours": "hour.",
      "short_minutes": "min."
    },
    helpPopUp: {
      message: `<h4>Number activation</h4>
         <p>If you do not receive an SMS with a code within the first 20 minutes, you can cancel the number, the money will be returned to your account in full.</p>

         <h4>Rent a number</h4>
         <p>During the selected period, you will always have the opportunity to receive an unlimited number of SMS from the selected service.</p>
         <p>You can always restore access to your registered account!</p>
         <p>If you do not receive an SMS with a code within the first 20 minutes, you can cancel the number, the money will be returned to your account in full.</p>
         <p>If you have not received an SMS and more than 20 minutes have passed, it will be impossible to cancel the rental.</p>
         <p>A number sold for rent will not be resold for the corresponding service upon expiration of the lease period.</p>
         <p>The minimum rental period is 4 hours, the maximum is 4 weeks.</p>
         <p>There are two options for renting a number:</p>
         <p>Full rental - in this case, you can receive all SMS, the services of which are presented in our catalog.</p>
         <p>Rent a number for a specific service. In this case, you will receive SMS only from the service you have chosen.</p>

         <h4>准备好的账户</h4>
         <p>不想浪费时间等短信?您可以购买我们合作伙伴开立的现成账户。Telegram、VK和其他服务的账户通常都是可用的。</p>
         <p>Telegram账户提供两种格式:TDATA(这种格式的账户可以在PC上登录，然后转移到移动设备上)和json+session(在这种情况下你需要特殊的软件)。</p>
         <p>使用现成帐户的细节，以及返回条件，在FAQ部分(网站菜单中的“支持”按钮)中有描述。请务必在购买前阅读此信息!</p>`
    }
  },
  comments: {
    "add_comment": "Add a comment",
    "comments": "Comments",
    "collapse": "Collapse",
    "expand": "Expand",
    "send_comment_moderation": "Your comment has been sent for moderation.",
    "ony_auth_user": "Available for registered members only"
  },
  saleAgreement: {
    "title": "Sale of licenses",
    "name": "Name",
    "type_po": "Software type",
    "price": "Price",
    "date_purchase": "Date of purchase",
  },
  freeNumbersPopUpRegistration: {
    subtitle: "The creation of an account with a free number was not working? Sign up and select a private number for the service — <span>from $0.01!</span>",
    button: "TRY"
  },
  accountShop: {
    mainSectionInfo: {
      my_paid_accounts_title: "My paid accounts",
      my_paid_accounts_message: "Here you see a list of all accounts ever purchased and the required login information",
      my_paid_accounts_button: "Purchasing accounts",

      purchasing_ready_accounts_title: "Purchasing accounts",
      purchasing_ready_accounts_message: "We understand that it is not always possible to receive SMS within 20 minutes. In this section you can buy a ready-made account for any popular service.",
      purchasing_ready_accounts_button: "My paid accounts",
    },
    selectedService: {
      title: "Select service",
      search_placeholder: "Search by service name"
    },
    selectedOffer: {
      title: "Select offer"
    },
    dialogOffer: {
      title: "Buy account",
      name_of_product: "Name of product",
      description_of_product: "Product description",
      characteristics: "Characteristics",
      registration_method: "Registration method",
      country: "Country",
      account_format: "Account format",
      availability_cloud_password: "Cloud password",
      information_about_purchased_product: "Information about the purchased product",
      download_files: "Download files",
      buy_more: "Buy more",
      yes: "Yes",
      no: "No",
      minimum_order_quantity: "Minimum order quantity"
    },
    myServices: {
      messageAuth: "You need to log in to your account",
      messageEmpty: "No purchased accounts"
    }
  },

  partner: {
    breadcrumbs_title: "Partner",
    documentation: "Documentation",
    activations_history: {
      activations_history: "Activations history",
      filter: "Filter",
      export_in: "Export to",
      number: "Number",
      load_more: 'Show more',
      total_rows: 'Total: {{ count }} rows',
      select_status: 'Select status',
      date_activation: 'Activation date',
      date_from: 'Date from',
      date_to: 'Date to',
      ready: 'Ready',
      canceled: 'Canceled',
      activation: "Activation"
    },
    sim_card: {
      sim_card: "Sim Card",
      port: 'Port',
      info: 'Info',
      earned: 'Earned',
      countServices: 'Count services'
    },

    price_regulator: {
      price_regulator: "Price regulator",
      select_country_started: "Select a country to get started",
      services: "Services",
      search_service: "Search by service",
      all_services: "All services",
      popular_services: "Popular",
      market_price: "Set market price",
      competitors_prices: "Show competitor prices",
      pice_successfully_changed: "Price successfully changed",
    },

    withdrawal_founds: {
      withdrawal_founds: 'Withdrawal founds',
      balance: 'Your balance',
      order_payments: 'Order a payment',
      currency_type: 'Currency type',
      withdrawal_method: 'Withdrawal method',
      order_status: 'Order status',
      select_method: 'Select a method',
      select_status: 'Select status',
      requisites: 'Requisites',
      sum: 'Sum',
      currency: 'Currency',
      date: 'Date',
      status: 'Status',
      nothing_found: 'Nothing found',
      amount_in_currency: 'Amount in currency',
      wallet_card_number: 'Wallet/card number',
      comment: 'Comment',
      not_necessary_placeholder: 'Not necessary',
      all_btn: 'All',
      error_required: 'Fill in all required fields',
      success_msg: 'Your application is accepted',
      warning_message: '<span>IMPORTANT!</span> Make sure you entered the correct payment details when creating your application above️. The administration of grizzlysms.com is not responsible in case of loss of funds due to incorrect payment details.'
    },

    statistic: {
      statistic: 'Monetization statistic',
      average_SIM: 'Average <br/> per SIM',
      top_services: 'Top services <br/>for 24 hours',
      activations_count: 'Activations count <br />for 24 hours',
      last_24_hours: 'For last <br/>24 hours'
    },
    dialog_competitors_prices: {
      your_price: "Your price",
      competitors_prices: "Competitors' prices"
    },
  },
  newPayments: {
    minAmount: '最低付款金额:',
    commission: '手续费:',
  },
  soft: {
    "submit_application": "Submit application",
    "search_by_name": "Search by name",
    "all_services": "All<br/>services",
    "activations": "Activations"
  },
  productLicensesBlock: {
    availableAt: "Available at",
  },
  documentationApi: {
    rent: "API rent",
    tables: "Tables",
    activations: "API activations",
    expandContent: "Expand content",
    collapseContent: "Collapse content",
    successLinkCopy: "Link copied successfully",
    successApiKeyCopy: "API Key copied successfully",
  },
  marketplaceRegistrationPage: {
    pageTitle: "店铺注册",
    formInputs: {
      name: {
        label: '为你的商店起一个名字。或许你的称呼能体现出你产品的本质，对买家的选择有帮助。',
        placeholder: '姓名',
        errorMessage: '需要填写'
      },
      shopDescription: {
        label: '店铺描述',
        placeholder: '对商店进行描述，突出其独特性和特色......',
        errorMessage: '需要填写'
      },
      email: {
        label: '您的电子邮件',
        placeholder: '请输入您的电子邮件地址以获取反馈...',
        errorMessageForNull: '需要填写',
        errorMessageNoValid: '需要填写'
      },
    },
    formSendButton: '注册店铺',
    rulesMarket: {
      iAgree: '我同意',
      rulesPhrase: 'Grizzlysms.com 市场规则和规定'
    },
    offerAgreement: {
      iAccept: '我接受',
      offerPhrase: '要约协议'
    },
    toastSuccessMessage: '您的申请已发送'
  }
}
